import React from 'react'
import styles from './sec4.module.css'
import xIcon from 'assets/xIcon.svg'

function Modal({ img, name, desc, hide }) {
  return (
    <div className={styles.layer_wrapper}>
      <div className={styles.layer_container}>
        <div
          className={styles.modal_wrapper}
          data-aos="fade-up"
          data-aos-duration="600"
        >
          <button className={styles.xBox} onClick={hide}>
            <img src={xIcon} alt="" className={styles.xIcon} />
          </button>
          <div className={styles.modal_container}>
            <div className={styles.modalTitle}>
              <img src={img} alt="" className={styles.modalImg} />
              <p className={styles.modalName}>{name}</p>
            </div>
            <p className={styles.desc}>{desc}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
