import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './footer.module.css'
import locationIcon from 'assets/footer/location.svg'
import phoneIcon from 'assets/footer/phone.svg'
import companyIcon from 'assets/footer/company.svg'
import twitterIcon from 'assets/footer/twitter.svg'
import facebookIcon from 'assets/footer/facebook.svg'
import linkIcon from 'assets/footer/link.svg'

function Footer() {
  return (
    <footer className={styles.wrapper}>
      <footer className={styles.container}>
        <div className={styles.left}>
          <div className={styles.fNavBox}>
            <h1 className={styles.title}>Our Services</h1>
            <NavLink to="overview" className={styles.fNav}>
              Overview
            </NavLink>
            <NavLink to="cell" className={styles.fNav}>
              Cell therapy
            </NavLink>
            <NavLink to="genetic" className={styles.fNav}>
              Genetic therapy
            </NavLink>
          </div>

          <div
            className={styles.fNavBox}
            style={{ marginRight: 80, marginLeft: 80 }}
          >
            <h1 className={styles.title}>LifeBank</h1>
            <NavLink to="lifebank" className={styles.fNav}>
              Lifebank
            </NavLink>
            <NavLink to="/health" className={styles.fNav}>
              LBC health
            </NavLink>
          </div>

          <div className={styles.fNavBox}>
            <h1 className={styles.title}>Career</h1>
            <NavLink to="/career" className={styles.fNav}>
              Career
            </NavLink>
          </div>
        </div>

        <div className={styles.right}>
          <span className={styles.fData}>
            <img src={companyIcon} alt="" className={styles.icon} />
            GENE & CELL MEDICINE LTD
          </span>
          <span className={styles.fData}>
            <img src={locationIcon} alt="" className={styles.icon} />
            No&#46;23 Hillel Street&#44; Jerusalem&#44; Israel&#46; Postal code
            &#58; 9458123
          </span>
          <span className={styles.fData}>
            <img src={phoneIcon} alt="" className={styles.icon} />
            &#43;61 29289991
          </span>

          <div className={styles.socialBox}>
            <a href="https://twitter.com/lifebankchain">
              <img src={twitterIcon} alt="" className={styles.socialIcon} />
            </a>
            <a href="/#" style={{ marginRight: 30, marginLeft: 30 }}>
              <img src={facebookIcon} alt="" className={styles.socialIcon} />
            </a>
            <a href="https://www.linkedin.com/company/lifebank-chain">
              <img src={linkIcon} alt="" className={styles.socialIcon} />
            </a>
          </div>
        </div>
      </footer>
    </footer>
  )
}

export default Footer
